<template>
  <div class="draft-music-review">
    <j-form model="drafts.draft" hide-errors>

      <!-- Artist -->
      <j-control
        name="title"
        label="Artist"
      />

      <!-- Title -->
      <j-control name="album" />

      <!-- Label -->
      <j-control name="label" />
    </j-form>

    <!-- Article Body -->
    <draft-article-body :draft="draft" />
  </div>
</template>

<script>

import DraftArticleBody from './DraftArticleBody'

export default {
  name: 'DraftMusicReview',
  components: {
    DraftArticleBody
  },
  props: ['draft']
}

</script>
